import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ControllerProtocolSettings } from '@priva/next-model';

import { X_HEADER } from 'app/core/http';
import { ControllersActions } from 'app/solution/controllers/state';
import { SolutionStateContainer } from 'app/solution/state';
import { ConnectivityApiActions } from 'app/template/system-component/configuration-view/connectivity-configurations/state';

import { NextLocalStorageService } from '../../local-storage/next-local-storage.service';
import {
    extractControllerProtocolSettingsElementIdFromNextApi,
    extractSolutionIdFromNextApi,
} from '../offline-sync.helper';

@Injectable()
export class OfflineSyncControllerProtocolSettingsInterceptor implements HttpInterceptor {
    private readonly localStorageService = inject(NextLocalStorageService);
    private readonly store$ = inject<Store<SolutionStateContainer>>(Store);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            request.headers.get(X_HEADER) === ControllersActions.getControllerProtocolSettings.type ||
            request.headers.get(X_HEADER) === ConnectivityApiActions.getControllerProtocolSettings.type
        ) {
            const solutionId = extractSolutionIdFromNextApi(request.url);
            const elementId = extractControllerProtocolSettingsElementIdFromNextApi(request.url);
            return this.store$.pipe(
                first(),
                switchMap((state) => {
                    if (state.solution?.active?.offline) {
                        return from(
                            this.localStorageService.getControllerProtocolSettings(solutionId, elementId),
                        ).pipe(
                            map(
                                (controllerProtocolSettings: ControllerProtocolSettings) =>
                                    new HttpResponse({ body: controllerProtocolSettings }),
                            ),
                        );
                    }
                    return next.handle(request);
                }),
            );
        }

        return next.handle(request);
    }
}
